
import {defineComponent, ref} from "vue";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import {bus} from "@/modules/eventBus";
import {ProblemDetails} from "@/models/ProblemDetails";
import {GroupService} from "@/services/GroupService";
import Swal from "sweetalert2";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {useToast} from "vue-toastification";
import GroupModal from "@/components/modals/GroupModal.vue";
import {PeopleService} from "@/services/PeopleService";
import {Person} from "@/models/people/Person";
import Button from "@/components/UI/Button.vue";

export default defineComponent({
  setup() {
    const groupModal = ref<InstanceType<typeof GroupModal>>();
    const userStore = useUser();
    const applicationStore = useApplication();
    const toast = useToast();

    //Invoke Add Group
    const invokeAddGroup = () => {
      groupModal.value?.toggleAdd();
    }
    //Invoke Manage Group
    const invokeManageGroup = (group: GroupViewModel) => {
      groupModal.value?.toggleUpdate(group);
    }

    return {userStore, applicationStore, toast, invokeAddGroup, invokeManageGroup, groupModal}
  },
  components: {Button, GroupModal},
  props: ['external'],
  data() {
    return {
      groups: [] as Array<GroupViewModel>,
      people: [] as Array<Person>,
      loading: true,
    }
  },
  mounted() {
    this.fetch()
    bus.on('modal:groups:added', this.fetch)
    bus.on('modal:groups:updated', this.fetch)
    bus.on('modal:groups:invoked', this.invokeAddGroup)
  },
  methods: {
    fetch() {
      this.loading = true
      if (this.isOrganization) {
        PeopleService.fetchOrganization(this.organizationId).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        })
        if (this.userStore.canManage(this.userStore.currentEntity)) {
          GroupService.fetchOrganization(this.organizationId).then(response => {
            this.groups = response.data
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            this.loading = false
          })
        }
      } else {
        PeopleService.fetchProduction(this.productionId).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        })
        if (this.userStore.canManage(this.userStore.currentEntity)) {
          GroupService.fetchProduction(this.productionId).then(response => {
            this.groups = response.data
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            this.loading = false
          })
        }
      }
    },
    removeGroup(group: GroupViewModel) {
      Swal.fire({
        title: `Are you sure you want to remove ${group.name}?`,
        confirmButtonText: 'Remove',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      }).then(response => {
        if (response.isConfirmed) {
          this.loading = true
          GroupService.removeGroup(group.groupId).then(() => {
            Swal.fire(`${group.name} has been removed.`)
            this.fetch()
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      if (this.isOrganization) {
        return '00000000-0000-0000-0000-000000000000'
      }
      return this.$route.params['productionId'] as string
    },
    /**
     * We don't want to show the system-generated "Everyone" Group
     */
    groupsFiltered(): Array<GroupViewModel> {
      return this.groups.filter(x => !x.isEveryone)
    },
  }
})
