import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex mb-4 mt-8" }
const _hoisted_2 = { class: "d-none d-md-block mb-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_ModalBase = _resolveComponent("ModalBase")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    title: _ctx.title,
    size: "modal-lg",
    ref: "modal",
    "is-loading": _ctx.isLoading
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.mode !== 'add')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "btn-sm ms-auto",
              "is-loading": _ctx.isLoading,
              onClick: _ctx.removeGroup,
              type: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Remove ")
              ]),
              _: 1
            }, 8, ["is-loading", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputGroup, {
          label: "Name",
          "is-required": true
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: _normalizeClass(["form-control", {'is-invalid': _ctx.v$.group.name.$error}]),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.group.name) = $event))
            }, null, 2), [
              [_vModelText, _ctx.group.name]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_InputGroup, {
          label: "Description",
          "is-required": false
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.group.description) = $event))
            }, null, 512), [
              [_vModelText, _ctx.group.description]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_InputGroup, {
          label: "Members",
          "is-required": false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Multiselect, {
              modelValue: _ctx.group.members,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.group.members) = $event)),
              options: _ctx.availablePeople,
              mode: "tags",
              searchable: true,
              trackBy: "label"
            }, null, 8, ["modelValue", "options"])
          ]),
          _: 1
        })
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_Button, {
          onClick: _ctx.toggleClosed,
          type: "secondary",
          class: "btn-sm me-6"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }, 8, ["onClick"]),
        (_ctx.mode === 'update')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _ctx.update,
              type: "primary",
              "is-loading": _ctx.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save Changes")
              ]),
              _: 1
            }, 8, ["onClick", "is-loading"]))
          : (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              onClick: _ctx.add,
              type: "primary",
              "is-loading": _ctx.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Add Group")
              ]),
              _: 1
            }, 8, ["onClick", "is-loading"]))
      ])), [
        [_directive_loading, _ctx.isLoading]
      ])
    ]),
    _: 1
  }, 8, ["title", "is-loading"]))
}