export interface CallType {
    callTypeId: string,
    name: string,
    color: number
}

export const getColorFriendlyName = (color: number) => {
    const colorOptions = [
        {
            text: 'Default',
            id: 0
        }, {
            text: 'Red',
            id: 1
        }, {
            text: 'Rust',
            id: 2
        }, {
            text: 'Pink',
            id: 3
        }, {
            text: 'Dark Blue',
            id: 4
        }, {
            text: 'Light Blue',
            id: 5
        }, {
            text: 'Yellow',
            id: 6
        }, {
            text: 'Orange',
            id: 7
        }, {
            text: 'Gray',
            id: 8
        }, {
            text: 'Purple',
            id: 9
        }, {
            text: 'Green',
            id: 10
        }, {
            text: 'Light Pink',
            id: 11
        }, {
            text: 'Dark Blue',
            id: 12
        }, {
            text: 'Dark Purple',
            id: 13
        }, {
            text: 'Light Yellow',
            id: 14
        }, {
            text: 'Light Orange',
            id: 15
        }, {
            text: 'Azure',
            id: 16
        }
    ];
    try {
        return colorOptions[color].text;
    } catch {
        return 'default'
    }
}

export const getColorName = (color: number) => {
    if (typeof color === 'undefined' || !color) return 'default'
    const colorOptions = [
        {
            text: 'default',
            id: 0
        }, {
            text: 'bloodred',
            id: 1
        }, {
            text: 'rust',
            id: 2
        }, {
            text: 'pink',
            id: 3
        }, {
            text: 'darkblue',
            id: 4
        }, {
            text: 'lightblue',
            id: 5
        }, {
            text: 'yellow',
            id: 6
        }, {
            text: 'orange',
            id: 7
        }, {
            text: 'grayer',
            id: 8
        }, {
            text: 'purple',
            id: 9
        }, {
            text: 'green',
            id: 10
        }, {
            text: 'lightpink',
            id: 11
        }, {
            text: 'darkblue',
            id: 12
        }, {
            text: 'darkpurple',
            id: 13
        }, {
            text: 'lightyellow',
            id: 14
        }, {
            text: 'lightorange',
            id: 15
        }, {
            text: 'azure',
            id: 16
        }
    ];
    try {
        return colorOptions[color].text;
    } catch {
        return 'default'
    }
}
