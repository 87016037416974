
import {defineComponent, PropType, reactive, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import {Person} from "@/models/people/Person";
import {bus} from "@/modules/eventBus";
import Multiselect from "@vueform/multiselect";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import {GroupService} from "@/services/GroupService";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {useToast} from "vue-toastification";
import Swal from "sweetalert2";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let group = reactive<GroupViewModel>({groupId: '', members: [], name: '', description: '', isEveryone: false} as GroupViewModel);
    let mode = ref<string>('add');
    const toggleUpdate = (_group: GroupViewModel) => {
      Object.assign(group, _group)
      mode.value = 'update';
      modal.value?.toggle()
    }
    const toggleAdd = () => {
      group = {groupId: '', members: [], name: '', description: '', isEveryone: false} as GroupViewModel;
      mode.value = 'add';
      modal.value?.toggle()
    }
    const toggleClosed = () => {
      modal.value?.toggleClosed()
    }
    const toastr = useToast();
    return {modal, toggleUpdate, toggleAdd, mode, group, toggleClosed, v$: useVuelidate(), toastr}
  },
  props: {
    people: {
      required: true,
      type: Object as PropType<Array<Person>>
    }
  },
  data(){
    return {
      isLoading: false
    }
  },
  validations(){
    return {
      group: {
        name: {required}
      }
    }
  },
  components: {InputGroup, Button, ModalBase, Multiselect},
  mounted() {
    bus.on('modal:groups:add', () => {
      this.toggleAdd()
    })
  },
  computed: {
    availablePeople(): Array<Object> {
      return this.people.filter(x => x.status === MembershipStatus.Active).map(person => {
        return {label: person.fullName, value: person.accountId};
      })
    },
    title(): string {
      return this.mode === 'add' ? 'Add New Group' : `Manage ${this.group.name}`
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    }
  },
  methods: {
    add() {
      this.v$.group.$touch()
      if(!this.v$.group.$invalid) {
        this.isLoading = true
        if (this.isOrganization) {
          GroupService.addGroup({
            description: this.group.description,
            members: this.group.members,
            name: this.group.name,
            organizationId: this.organizationId
          }).then(this.emitAdded)
        } else {
          GroupService.addGroup({
            description: this.group.description,
            members: this.group.members,
            name: this.group.name,
            productionId: this.productionId
          }).then(this.emitAdded)
        }
      }
    },
    emitAdded(){
      this.toastr.success(`Added ${this.group.name}`)
      bus.emit('modal:groups:added')
      bus.emit('onboarding:production:fetch')
      this.isLoading = false
      this.v$.$reset()
      if(this.isOrganization){
        WebAnalytics.trackFlexible('Added Group', {
          organizationId: this.organizationId,
          membersCount: this.group.members.length
        })
      } else {
        WebAnalytics.trackFlexible('Added Group', {
          productionId: this.productionId,
          membersCount: this.group.members.length
        })
      }
      this.toggleClosed()
    },
    update() {
      this.v$.group.$touch()
      if(!this.v$.group.$invalid) {
        this.isLoading = true
        GroupService.updateGroup(this.group.groupId, {
          groupId: this.group.groupId,
          description: this.group.description,
          members: this.group.members,
          name: this.group.name,
        }).then(() => {
          this.toastr.success(`Updated ${this.group.name}`)
          bus.emit('modal:groups:updated')
          WebAnalytics.trackFlexible('Updated Group', {})
        }).finally(() => {
          this.v$.$reset()
          this.isLoading = false
          this.toggleClosed()
        })
      }
    },
    removeGroup() {
      Swal.fire({
        title: `Are you sure you want to remove ${this.group.name}?`,
        confirmButtonText: 'Remove',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      }).then(response => {
        if (response.isConfirmed) {
          this.isLoading = true
          GroupService.removeGroup(this.group.groupId).then(() => {
            Swal.fire(`${this.group.name} has been removed.`)
            this.toggleClosed()
            bus.emit('modal:groups:updated')
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
  }
})
