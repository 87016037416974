
import {defineComponent} from "vue";
import {bus} from "@/modules/eventBus";
import Utilities from "@/utilities/Utilities";
import {CallType, getColorName} from "@/models/scheduling/CallType";
import {CallTypeService} from "@/services/CallTypeService";
import {WebAnalytics} from "@/modules/webAnalytics";
import {useRoute} from "vue-router";
import {useQuery} from "@tanstack/vue-query";

export default defineComponent({
  setup() {
    const route = useRoute();
    const productionId = () => {
      return route.params['productionId'] as string
    }
    const { isLoading, isError, data, error, refetch } = useQuery({
      queryKey: [`calltypes`, productionId()],
      queryFn: ({ queryKey }) => CallTypeService.listProduction(queryKey[1] as string),
    })
    return {isLoading, isError, data, error, refetch}
  },
  props: ['external'],
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    fetch() {
      if (!this.isOrganization) {
        this.refetch()
        /*this.loading = true
        //this.loading = isLoading
        //this.calltypes = data.value
        CallTypeService.listProduction(this.productionId).then(response => {
          this.callTypes = response.data
          this.loading = false
        })*/
      }
    },
    addDefaults() {
      this.loading = true
      CallTypeService.create({
        name: 'Rehearsal',
        color: 0,
        productionId: this.productionId
      }).then(response => {
        bus.emit('modals:calltype:added', response.data)
      }).finally(() => {
        CallTypeService.create({
          name: 'Performance',
          color: 10,
          productionId: this.productionId
        }).then(response => {
          bus.emit('modals:calltype:added', response.data)
        }).finally(() => {
          CallTypeService.create({
            name: 'Dress Rehearsal',
            color: 9,
            productionId: this.productionId
          }).then(response => {
            bus.emit('modals:calltype:added', response.data)
          }).finally(() => {
            this.loading = false
            WebAnalytics.trackFlexible('Added Default Call Types', {
              productionId: this.productionId
            })
          })
        })
      })
    },
    addCallType() {
      bus.emit('modals:calltype:add')
    },
    updateCallType(callType: CallType) {
      bus.emit('modals:calltype:update', callType)
    },
    handleCallTypeAdded(callType: CallType) {
      this.callTypes.push(callType)
      this.fetch()
    },
    handleCallTypeRemoved(callTypeId: string) {
      Utilities.removeObject(this.callTypes, 'callTypeId', callTypeId)
      this.fetch()
    },
    getCallTypeColor(color: number) {
      return getColorName(color);
    },
  },
  mounted() {
    bus.on('modals:schedule:settings', () => {
      this.fetch()
    })
    bus.on('modals:calltype:added', (callType) => {
      this.handleCallTypeAdded(callType as CallType)
    })
    bus.on('modals:calltype:removed', (callTypeId) => {
      this.handleCallTypeRemoved(callTypeId as string)
    })
    bus.on('modals:production:fetch', () => {
      this.fetch()
    })
    // this.fetch()
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    isFetched(): boolean {
      return this.isFetched
    },
    queryData(): any {
      return this.data
    },
    callTypes(): Array<CallType>{
      if(typeof this.data !== 'undefined' && this.data && this.data.data){
        return this.data.data as Array<CallType>
      }
      return []
    }
  }
})
