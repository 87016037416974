
import {defineComponent, ref} from "vue";
import Button from "@/components/UI/Button.vue";
import {useUser} from "@/store/pinia/userStore";
import {ProductionMembership} from "@/models/productions/ProductionMembership";
import VenueSettings from "@/components/SelfContained/VenueSettings.vue";
import CallTypeSettings from "@/components/SelfContained/CallTypeSettings.vue";
import GroupSettings from "@/components/SelfContained/GroupSettings.vue";
import PeopleSettings from "@/components/SelfContained/PeopleSettings.vue";
import {OrganizationService} from "@/services/OrganizationService";
import {ProductionService} from "@/services/ProductionService";
import {UploadFile, UploadProgressEvent, UploadRawFile} from "element-plus/es/components/upload/src/upload";
import {ElMessage} from "element-plus";
import {bus} from "@/modules/eventBus";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  components: {PeopleSettings, GroupSettings, CallTypeSettings, VenueSettings, Button},
  props: ['productionId'],
  setup() {
    const userStore = useUser()
    const imageUrl = ref('')
    const handleAvatarSuccess = (res: UploadProgressEvent, file: UploadFile) => {
      imageUrl.value = URL.createObjectURL(file.raw)
    }
    const beforeAvatarUpload = (file: UploadRawFile) => {
      const isJPG = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        ElMessage.error('Logo must be an image')
      }
      if (!isLt2M) {
        ElMessage.error('Logo size can not exceed 8MB')
      }
      return isJPG && isLt2M
    }
    return {userStore, imageUrl, handleAvatarSuccess, beforeAvatarUpload}
  },
  data() {
    return {
      subStep: 0,
      photoIsLoading: false,
      hasAvatarQueued: false,
      photoStepComplete: false,
      onboardingIsLoading: true,
      callTypesCompleted: false,
      onboardStatus: {photo: '', hasGroups: false, hasPeople: false, hasCallTypes: false, hasVenues: false} as any
    }
  },
  mounted() {
    bus.on('onboarding:production:fetch', this.fetchOnboardStatus)
    this.fetchOnboardStatus()
    bus.on('modals:cropper:handle:production-onboard', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  methods: {
    backToChecklist() {
      if (this.subStep === 2) {
        this.callTypesCompleted = true
      }
      this.subStep = 0
    },
    fetchOnboardStatus() {
      ProductionService.fetchOnboardStatus(this.productionId).then(response => {
        this.onboardStatus = response.data
        this.onboardingIsLoading = false
        // bus.emit('modals:production:fetch')
      })
    },
    returnToOnboardHome() {
      this.$router.push({name: 'onboarding'})
    },
    skip() {
      WebAnalytics.trackFlexible('Skipped Onboarding', {entityType: 'Production', progress: this.progress})
      this.goToProduction()
    },
    finish() {
      WebAnalytics.trackFlexible('Finished Onboarding', {entityType: 'Production'})
      this.goToProduction()
    },
    goToProduction() {
      if (this.isFromFlow) {
        this.$router.push({name: 'onboarding', query: {prodStatus: this.completed ? 'completed' : 'skip'}})
      } else {
        this.$router.push({name: 'production-overview', params: {productionId: this.productionId}})
      }
    },
    requestCropper(xhr) {
      bus.emit('modals:cropper:open', {
        blob: xhr.file,
        requester: 'modals:cropper:handle:production-onboard',
        isUserPhoto: false
      })
    },
    uploadPhoto(blob) {
      this.photoIsLoading = true
      const formData = new FormData();
      formData.append('file', blob);
      if (this.isOrganization) {
        OrganizationService.uploadPhoto(this.organizationId, formData).then(() => {
          this.hasAvatarQueued = false
          this.userStore.fetchOrganizationMemberships()
          this.photoStepComplete = true
        }).finally(() => {
          this.photoIsLoading = false
          this.fetchOnboardStatus()
        })
      } else {
        ProductionService.uploadPhoto(this.productionId, formData).then(() => {
          this.hasAvatarQueued = false
          this.userStore.fetchProductionMemberships()
          this.photoStepComplete = true
        }).finally(() => {
          this.photoIsLoading = false
          this.fetchOnboardStatus()
        })
      }
    },
    addCallType() {
      bus.emit('modals:calltype:add')
    },
    addVenue() {
      bus.emit('modals:venue:add')
    },
    addGroup() {
      bus.emit('modal:groups:invoked')
    },
    addPeople() {
      bus.emit('modals:people:invoke')
    }
  },
  computed: {
    completed(): boolean {
      return (this.onboardStatus.hasGroups && this.onboardStatus.hasPeople && this.onboardStatus.hasVenues && this.onboardStatus.hasCallTypes) &&
          (this.onboardStatus.photo !== '' || this.onboardStatus.photo !== null)
    },
    progress(): number {
      let totalCompleted = 1

      if (this.onboardStatus.hasGroups) {
        totalCompleted += 1
      }
      if (this.onboardStatus.hasPeople) {
        totalCompleted += 1
      }
      if (this.onboardStatus.hasVenues) {
        totalCompleted += 1
      }
      if (this.onboardStatus.hasCallTypes) {
        totalCompleted += 1
      }
      if (this.onboardStatus.photo !== '' && this.onboardStatus.photo) {
        totalCompleted += 1
      }

      const total = 6
      return (totalCompleted / total) * 100
    },
    production(): ProductionMembership {
      return this.userStore.productions.filter(p => p.productionId === this.productionId)[0]
    },
    prodName(): string {
      if (this.production) {
        return this.production.name
      } else {
        return ''
      }
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    headerTitle(): string {
      switch (this.subStep) {
        case 0:
          return `Set up ${this.prodName}`
        case 1:
          return 'Add Venue & Rehearsal Spaces'
        case 2:
          return 'Add Call Types'
        case 3:
          return 'Create Groups'
        case 4:
          return 'Invite People'
        default:
          return `Set up ${this.prodName}`
      }
    },
    headerSubtitle(): string {
      switch (this.subStep) {
        case 0:
          return `get your production up and running in less than five minutes`
        case 1:
          return 'add venues (buildings) with spaces (rooms) that can be used when scheduling calls like rehearsals and performances'
        case 2:
          return 'call types help you color-code your calendars'
        case 3:
          return 'create custom groups like cast, crew, production team, ensemble to make it easier to schedule calls and send out communications'
        case 4:
          return 'invite your cast and crew to your production'
        default:
          return ``
      }
    },
    isFromFlow(): boolean {
      return !!this.$route.query.fromFlow
    }
  }
})
