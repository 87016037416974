import {AxiosResponse} from "axios";
import {apiClient} from "@/modules/apiClient";
import {CallType} from "@/models/scheduling/CallType";
import {CallTypeCommand} from "@/models/scheduling/CallTypeCommand";
import { useQuery } from "@tanstack/vue-query";

export const CallTypeService = {
    listProduction(productionId: string): Promise<AxiosResponse<Array<CallType>>> {
        return apiClient.get(`/productions/${productionId}/calltypes`)
    },
    listProductionQuery(productionId: string) {
        return useQuery({
            queryKey: [`calltypes-${productionId}`, productionId],
            queryFn: ({ queryKey }) => this.listProduction(queryKey[1]),
        })
    },
    create(command: CallTypeCommand): Promise<AxiosResponse<CallType>> {
        return apiClient.post(`/calltypes`, command)
    },
    update(callTypeId: string, command: CallType): Promise<AxiosResponse> {
        return apiClient.put(`/calltypes/${callTypeId}`, command)
    },
    remove(callTypeId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/calltypes/${callTypeId}`)
    },
}
